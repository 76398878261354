<template>
  <div>
    {{leave}}
  </div>
</template>
<script>
import { requestService } from '@/services'
export default {
  data () {
    return {
      leave: null
    }
  },
  mounted () {
    requestService.getLeave(this.$route.params.id).then(result => {
      console.log(result)
      this.leave = result.data
    }).catch(error => {
      console.log('this Error', error)
    })
  }
}
</script>
<style scoped>

</style>
